import React from "react";
import { NavLink } from "react-router-dom";
import "../styles/Header.css"; // CSS for styling the header

function Header() {
  return (
    <header className="header">
      <nav className="nav">
        <NavLink to="/" className="nav-link" end>
          Home
        </NavLink>
        <NavLink to="/WhatsAppDisplay" className="nav-link">
          WhatsApp Display
        </NavLink>
        <NavLink to="/MinecraftPortals" className="nav-link">
          Minecraft Portals
        </NavLink>
      </nav>
    </header>
  );
}

export default Header;

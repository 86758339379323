import React from "react";
import CWhatsAppDisplay from "../applications/whatsapp-chat-viewer/src/App";

function WhatsAppDisplay() {
  return (
    <div>
        <CWhatsAppDisplay />
    </div>
  );
}

export default WhatsAppDisplay;

import React, { useState } from "react";
import "./App.css";

function App() {
  const [portals, setPortals] = useState([]);
  const [distances, setDistances] = useState([]);

  // Add a new portal
  const addPortal = () => {
    setPortals([
      ...portals,
      { name: "", x: 0, y: 0, z: 0, isOverworld: true },
    ]);
  };

  // Remove a portal by index
  const removePortal = (index) => {
    const updatedPortals = portals.filter((_, i) => i !== index);
    setPortals(updatedPortals);
  };

  // Handle input changes for a portal
  const handleInputChange = (index, field, value) => {
    const updatedPortals = [...portals];
    updatedPortals[index][field] = field === "isOverworld" ? value === "true" : value;
    setPortals(updatedPortals);
  };

  // Calculate pairwise distances
  const calculateDistances = () => {
    const results = [];

    portals.forEach((portal1, i) => {
      portals.forEach((portal2, j) => {
        if (i < j) {
          const x1 = portal1.isOverworld ? portal1.x : portal1.x * 8;
          const z1 = portal1.isOverworld ? portal1.z : portal1.z * 8;
          const x2 = portal2.isOverworld ? portal2.x : portal2.x * 8;
          const z2 = portal2.isOverworld ? portal2.z : portal2.z * 8;

          const dx = x1 - x2;
          const dy = portal1.y - portal2.y;
          const dz = z1 - z2;

          const distance = Math.sqrt(dx ** 2 + dy ** 2 + dz ** 2);

          results.push({
            pair: `${portal1.name || "(" + portal1.x + "," + portal1.y + "," + portal1.z + ")"} ↔ ${portal2.name || "(" + portal2.x + "," + portal2.y + "," + portal2.z + ")"}`,
            distance: distance.toFixed(2),
          });
        }
      });
    });

    // Sort results by distance
    results.sort((a, b) => parseFloat(a.distance) - parseFloat(b.distance));
    setDistances(results);
  };

  return (
    <div className="App">
      <header className="App-header">
        <h1>Minecraft Portal Distance Calculator</h1>
        <button onClick={addPortal} disabled={portals.length >= 6}>
          Add Portal
        </button>

        <div className="portals">
          {portals.map((portal, index) => (
            <div
              key={index}
              className={`portal ${portal.isOverworld ? "overworld" : "nether"}`}
            >
              <input
                type="text"
                placeholder="Portal Name"
                value={portal.name}
                onChange={(e) =>
                  handleInputChange(index, "name", e.target.value)
                }
              />

              <div className="portal-row">
              <p>X:</p>
                <input
                  type="number"
                  placeholder="X"
                  value={portal.x}
                  onChange={(e) =>
                    handleInputChange(index, "x", parseFloat(e.target.value))
                  }
                />
                <p>Y:</p>
                <input
                  type="number"
                  placeholder="Y"
                  value={portal.y}
                  onChange={(e) =>
                    handleInputChange(index, "y", parseFloat(e.target.value))
                  }
                />
                <p>Z:</p>
                <input
                  type="number"
                  placeholder="Z"
                  value={portal.z}
                  onChange={(e) =>
                    handleInputChange(index, "z", parseFloat(e.target.value))
                  }
                />
                <select
                  value={portal.isOverworld ? "true" : "false"}
                  onChange={(e) =>
                    handleInputChange(index, "isOverworld", e.target.value)
                  }
                >
                  <option value="true">Overworld</option>
                  <option value="false">Nether</option>
                </select>
              </div>

              <button onClick={() => removePortal(index)} className="remove-button">
                Remove
              </button>
            </div>
          ))}
        </div>

        <button onClick={calculateDistances} disabled={portals.length < 2}>
          Calculate Distances
        </button>

        {distances.length > 0 && (
          <div className="results">
            <h2>Pairwise Euclidean Distances</h2>
            <ul>
              {distances.map((item, idx) => (
                <li key={idx}>
                  {item.pair}: {item.distance} blocks
                </li>
              ))}
            </ul>
          </div>
        )}
      </header>
    </div>
  );
}

export default App;

import React, { useState } from "react";
import "./ChatDisplay.css";
import PdfViewer from "./PdfViewer";
import AudioMessage from "./AudioMessage";

function ChatDisplay({ messages = [], me, other }) {
  // modal for images
  const [modalImage, setModalImage] = useState(null);
  const openImage = (image) => setModalImage(image);
  const closeModalImage = () => setModalImage(null);

  // modal for pdfs
  const [modalPdf, setModalPdf] = useState(null);
  const openPdf = (pdf) => setModalPdf(pdf);
  const closeModalPdf = () => setModalPdf(null);


  return (
    <div className="chat-wrapper">
    {/* Header */}
      <div className="chat-header">
        <button className="back-button">←</button>
        <div className="header-info">
          <h2 className="partner-name">{other}</h2>
        </div>
      </div>

    {/* Message Container */}
      <div className="chat-container">
        {messages.map((msg, index) => (
          <div
            key={index}
            className={`chat-message ${msg.sender === me ? "self" : "other"}`}
          >
            <div className="message-info">
              <span className="sender">{msg.sender}</span>
              <span className="time">{msg.time}</span>
            </div>
            <div className="message-text">
                {msg.isImage ? (
                    <img
                        src={msg.url}
                        alt="Chat Attachment"
                        className="chat-image"
                        onClick={() => openImage(msg.url)} // Bild bei Klick öffnen
                    />
                ) : msg.isAudio ? (
                    <AudioMessage audioSrc={msg.url} />
                ) : msg.isPdf ? (
                    <p className="pdflink" onClick={() => openPdf(msg.url)}> {msg.message} </p>
                ) : (
                    msg.message
                )}
            </div>
          </div>
        ))}
      </div>

        {/* Footer */}
        <div className="chat-footer">
            <button className="attachment-button">📎</button>
            <input
            type="text"
            placeholder="Type a message..."
            className="message-input"
            />
            <button className="send-button">
            ➤
            </button>
        </div>



      {/* Modal für Bildanzeige */}
      {modalImage && (
        <div className="image-modal" onClick={closeModalImage}>
          <img src={modalImage} alt="Full View" className="modal-image" />
          <button className="close-button" onClick={closeModalImage}>×</button>
        </div>
      )}

      {/* Modal für Pdfs */}
      {modalPdf && (
        <div className="image-modal" onClick={closeModalPdf}>
          <PdfViewer pdfSrc={modalPdf} />
          <button className="close-button" onClick={closeModalPdf}>×</button>
        </div>
      )}
     

    </div>
  );
}

export default ChatDisplay;

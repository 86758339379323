import React from 'react';

const AudioMessage = ({ audioSrc }) => {
  return (
    <audio controls>
    <source src={audioSrc} type="audio/ogg" />
        Your browser does not support the audio element.
    </audio>
  );
};

export default AudioMessage;

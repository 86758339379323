import React from "react";
import CMinecraftPortalCalculator from "../applications/minecraft-portal-calculator/src/App";

function MinecraftPortalCalculator() {
  return (
    <div>
      <CMinecraftPortalCalculator />
    </div>
  );
}

export default MinecraftPortalCalculator;

import React, { useState } from "react";
import ChatUploader from "./ChatUploader";
import ChatDisplay from "./ChatDisplay";

function App() {
  const [messages, setMessages] = useState([]);
  const [me, setMe] = useState("");
  const [conversationParter, setConversationParter] = useState("");
  var participants = new Set();

  function handleParticipantSelection(e) {
    setMe(e.target.value);
    setConversationParter(participants[!e.target.value])
  }

  function getParticipants(messages) {
    participants = []
    
    
    return participants;// Convert Set to Array
  }

  const handleFolderUpload = (folderContent) => {
    
    

    const chatFile = folderContent.find((file) => file.name === "_chat.txt");
    const imageFiles = folderContent.filter((file) => file.name.match(/\.(jpg|png)$/)).map((file) => file.name);
    const audioFiles = folderContent.filter((file) => file.name.match(/\.(opus)$/)).map((file) => file.name);
    const pdfFiles = folderContent.filter((file) => file.name.match(/\.(pdf)$/)).map((file) => file.name);


    const reader = new FileReader();
    reader.onload = (event) => {
      const chatContent = event.target.result;
      const parsedData = parseChat(chatContent);
      
      // determine participants
      participants = getParticipants(parsedData.messages);
      console.log(participants)
      for (var p in participants) {
        console.log("PARTICIPANTS: " + p);
      } 
      // Attach image/audio path resolution to each message
      const messagesWithFilePaths = parsedData.messages.map((msg) => {
        if (msg.isImage && imageFiles.includes(msg.message)) {
          return {
            ...msg,
            url: URL.createObjectURL(
              folderContent.find((file) => file.name === msg.message)
            ),
          };
        }
        if (msg.isAudio && audioFiles.includes(msg.message)) {
          return {
            ...msg,
            url: URL.createObjectURL(
              folderContent.find((file) => file.name === msg.message)
            ),
          };
        }
        if (msg.isPdf && pdfFiles.includes(msg.message)) {
          // const file = folderContent.find((file) => file.name === msg.message);
          // console.log("File Type:", file.type);
          // console.log("File Name:", file.name);
          return {
            ...msg,
            url: URL.createObjectURL(
              folderContent.find((file) => file.name === msg.message)
            ),
          };
        }
        return msg;
      });

      setMessages(messagesWithFilePaths);
    };

    reader.readAsText(chatFile);
  };


  const parseChat = (text) => {
    const regex = /\[(\d{2}\.\d{2}\.\d{2}), (\d{2}:\d{2}:\d{2})] (.+?): (.+)/g;
    const messages = [];
    let match;
    

    while ((match = regex.exec(text)) !== null) {
      const [_, date, time, sender, message] = match;
      // Check if the message is an attachment
      const attachmentMatch = message.match(/<Anhang: (.+)>/);
      const isAttachment = !!attachmentMatch;

      var isAudio = false;
      var isImage = false;
      var isPdf = false;
      // Check the file type
      if (message.endsWith(".opus>")) {
        isAudio = true;
      } else if (message.endsWith(".pdf>")) {
        isPdf = true;
      } else if (message.endsWith(".jpg>") || message.endsWith(".jpeg>") || message.endsWith(".png>")) {
        isImage = true;
      }

      messages.push({
        date,
        time,
        sender,
        message: isAttachment ? attachmentMatch[1] : message,// Store either message or blob ref for docs
        filename: isAttachment ? attachmentMatch[0] : message,// Store either message or filename for attachments 
        isAttachment,
        isImage,
        isAudio,
        isPdf
      });
    }

    return { messages };
  };

  return (
    <div>
      <h1>WhatsApp Chat Viewer</h1>
      <ChatUploader onUpload={handleFolderUpload} />
      <div>
        <label>Select "Me": </label>
        <select onChange={handleParticipantSelection}>
          <option value="">--Select--</option>
          {[...new Set(messages.map((msg) => msg.sender))].map((participant) => (
            <option key={participant} value={participant}>
              {participant}
            </option>
          ))}
        </select>
      </div>
      <ChatDisplay messages={messages} me={me} other={conversationParter} />
    </div>
  );
}

export default App;

import React from "react";

function ChatUploader({ onUpload }) {
    const handleFileChange = (e) => {
      const files = Array.from(e.target.files);
      onUpload(files);
    };
  
    return (
      <div>
        <input
          type="file"
          webkitdirectory="true"
          directory="true"
          multiple
          onChange={handleFileChange}
        />
      </div>
    );
  }
  
export default ChatUploader;
  
  

import React from 'react';

const PdfViewer = ({ pdfSrc }) => {
    console.log("PDF SOURCE:" + pdfSrc);
    return (
        <embed src={pdfSrc} width="100%" height="600px" type="application/pdf" />
    )
  };
  
export default PdfViewer;
